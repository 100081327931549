<template lang="pug">
div
  loading(v-if="filteredProducts.length <= 0" key="1")
  .collection.all-products(v-else key="2")
    .collection-product(
      @click="goToProduct(product)"
      v-for="product in filteredProducts"
      :key="product.id"
    )
      vue-image.product-image(
        :source='product.images.length > 0 && product.images[0].url || ""'
        :height='500'
        :width='500'
      )
      h4.title {{ product.title }}
      .prices
        p(v-if="product.variants[0].compareAtPrice && product.variants[0].compareAtPrice.amount").original-price {{ product.variants[0].compareAtPrice.amount | currency }}
        p.price {{ product.variants[0].price.amount ? product.variants[0].price.amount : product.variants[0].price | currency }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { goToProduct } from '@/helpers/goToProduct'

export default {
  name: 'allProducts',

  computed: {
    ...mapState('products', [
      'upsellHandle'
    ]),

    ...mapGetters('products', [
      'orderedProducts'
    ]),

    filteredProducts () {
      return this.orderedProducts.filter((product) => product.handle !== this.upsellHandle)
    }
  },

  mounted () {
    if (this.orderedProducts.length <= 0) {
      this.$store.dispatch('products/fetchAllProducts')
    }
  },

  methods: {
    goToProduct
  }
}
</script>

<style lang="scss">
// not scoped to share with loading
.collection {
  position: relative;
  display: grid;
  padding: 0.25em;
  grid-gap: 1.25em;
  max-width: 100%;

  @media screen and (min-width: 20em) {
    grid-template-columns: repeat(2, 1fr);
  }

  .collection-product {
    cursor: pointer;
    text-align: left;
    transition: opacity 0.5s;

    .product-image {
      margin-bottom: 0.5em;
    }

    &:hover {
      opacity: 0.8;
    }

    .prices {
      display: flex;

      * {
        margin: 0;
      }

      .original-price {
        color: $black;
        position: relative;
        margin-right: 0.25em;

        &:after {
          position: absolute;
          content: "";
          left: 0;
          top: 50%;
          right: 0;
          border-top: 1px solid;
          border-color: inherit;
        }
      }

      .original-price + .price {
        color: $red;
      }
    }

    .title {
      margin: 0.25em 0 0;
    }
  }
}
</style>
