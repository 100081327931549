<template lang="pug">
  .products.max-width.page
    h1 Store
    p
      | Facilitated through Shopify, and fulfilled by yours truly.
      | #[i This is a demo store.]
    all-products
</template>

<script>
import AllProducts from '@/components/AllProducts.vue'

export default {
  name: 'Products',
  components: {
    AllProducts
  }
}
</script>
